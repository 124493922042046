import React, { useState } from 'react';
import { useDispatch} from 'react-redux';
import {  setWebsiteRefresh } from 'shared/infra/redux/global/global';
import BButton from 'shared/components/bButton';
import CustomizeUrlModal from 'shared/components/customizeUrlModal';
import { app } from 'shared/infra/config';

import './styles.scss';

interface Props {
    data: any;
}

const EditUrlForm: React.FC<Props> = ({ data }) => {
    const dispatch = useDispatch();
   
    const [isCustomModalVisible, setIsCustomModalVisible] = useState(false);

    return (
        <>
            <div className="editUrlFormContainer">
                <div className="text-and-edit-button">
                    <div className="url">{`${app?.APP_PUBLIC_URL}${data?.slug}`}</div>
                    <BButton
                        type="base4"
                        text={'Edit'}
                        classes={["w-100-p"]}
                        action={() => setIsCustomModalVisible(true)}
                    />
                </div>
            </div>

            <CustomizeUrlModal
                isCustomModalVisible={isCustomModalVisible}
                close={() => setIsCustomModalVisible(false)}
                data={data}
                actionAfterSuccess={() => dispatch(setWebsiteRefresh({ refresh: true }))}
            />
        </>
    );
};

export default EditUrlForm;
