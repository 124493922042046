import './styles.scss';
import React, { useRef } from 'react';
import classNames from 'classnames';
import { Col, Row, Image, Carousel } from 'antd';
import Paragraph from 'shared/components/paragraph';
import { formatDate } from 'shared/utils/helper';
import { useSelector } from 'react-redux';
import { getUser } from 'modules/user/redux/userSlice';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

type props = {
  classes?: string[];
  forModal?: boolean;
  type?: 'Update';
  handleEditUpdate: any;
  data: any;
  website: any;
};

const Update: React.FC<props> = ({ classes = [], forModal = false, type = 'update', data, handleEditUpdate, website }) => {
  const user = useSelector(getUser);
  const carouselRef = useRef<any>(null);

  const handleNext = () => {
    carouselRef.current?.next();
  };

  const handlePrev = () => {
    carouselRef.current?.prev();
  };

  return (
    <>
      <Row
        className={classNames('updateContainer', ...classes)}
        style={{ backgroundColor: forModal ? 'rgba(244, 229, 255, 0.50)' : '' }}
      >
        <Col className="firstRow" span={24}>
          <Paragraph classes={['name']}>{data?.title}</Paragraph>
          <div
            className="tag"
            style={{
              cursor: !!user && user?.id === website?.user_id ? 'pointer' : 'default',
            }}
            onClick={() => (!user ? {} : handleEditUpdate(data))}
          >
            {!!user && user?.id === website?.user_id ? 'Edit' : type}
          </div>
        </Col>
        <Col className="secondRow" span={24}>
          {data?.message}
          <br />
          <Paragraph classes={['date']}>
            {formatDate(data?.created_at, 'EEEE, MMMM dd')}
          </Paragraph>
        </Col>
        <Col span={24}>
          {data?.images?.length > 0 && (
            <div
              style={{
                width: '100%',
                height: '9.6875rem',
                overflow: 'hidden',
                borderRadius: '10px',
                marginTop: '10px',
                position: 'relative',
              }}
            >
              <Carousel ref={carouselRef} autoplay={true} dots={true}>
                {data?.images?.map((image: any, index: any) => (
                  <div key={index} className="carousel">
                    <Image
                      className="image"
                      src={image?.src}
                      alt={`Slide ${index + 1}`}
                      preview={true}
                    />
                  </div>
                ))}
              </Carousel>
              {
                data?.images?.length > 1 && (
                  <>
                    <button className="carousel-button left" onClick={handlePrev}>
                      <CaretLeftOutlined style={{ color: 'white' }}/>
                    </button>
                    <button className="carousel-button right" onClick={handleNext}>
                      <CaretRightOutlined style={{color: "white"}}/>
                    </button>
                  </>
                )
              }
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Update;
