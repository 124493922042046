import React from 'react';
import Share from '../../../../../../shared/components/share';
import './styles.scss';


interface Props {
    data: any;
}

const ShareBabyForm: React.FC<Props> = ({ data }) => {
    return (
        <div className='shareVillieForm'>
            <Share data={data} />
        </div>
    );
};

export default ShareBabyForm;
