import React, { useRef, useState } from "react";
import "./styles.scss";
import CustomModal from "../modal";
import BButton from "../bButton";
import { Carousel } from "antd"; 
import { DATETIME, formatDate } from 'shared/utils/helper';
interface Props {
    show: boolean;
    close: () => void;
}

const featureSlides = [
    {
        image: "/assets/Panel Image.png",
        title: "Drag-and-drop customization",
        text: "Easily rearrange your baby page with drag-and-drop controls. Personalize the layout to make it just right for your village.",
    },
    {
        image: "/assets/panel Image-2.png",
        title: "Ad-free experience",
        text: "Remove all ads and auto-added gifting options. With Premium, you choose how villagers send gifts-no branded extras unless you want them.",
    },
    {
        image: "/assets/Panel Image3.png",
        title: "Enhanced Privacy",
        text: "Lock your page with a passcode for extra security. Keep your updates private and share only with your chosen villagers.",
    },
    {
        image: "/assets/Panel Image4.png",
        title: "More fun games",
        text: "Unlock the Guess Who game and engage your villagers like never before! Plus,stay tuned-more games are coming soon!",
    }
];

const PremiumFeatureModal: React.FC<Props> = ({ show, close }) => {
    const carouselRef = useRef<any>(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [reminderSlide, setReminderSlide] = useState(false);

    // Handle Carousel Slide Change
    const handleSlideChange = (index: number) => {
        setCurrentSlide(index);
    };

    // Handle Next Button Click
    const handleNext = () => {
        if (reminderSlide) {
            close(); 
            return;
        }

        if (currentSlide < featureSlides.length - 1) {
            carouselRef.current.next(); 
        } else {
            setReminderSlide(true); 
        }
    };

    return (
        <CustomModal
            show={show}
            close={close}
            width={600}
            title=""
            showCloseBtn={true}
            maskClosable={true}
            destroyOnClose={true}
            classes={["premiumFeatureModal"]}
        >
            <div className="premiumFeatureModalContainer">
                <p className="title">Check out Premium Features!</p>

               
                {!reminderSlide ? (
                    <div className="slider">
                        <Carousel
                            ref={carouselRef}
                            dots={{ className: "custom-dots" }}
                            infinite={false} 
                            slidesToShow={1} 
                            slidesToScroll={1} 
                            adaptiveHeight={true} 
                            centerMode={false} 
                            afterChange={handleSlideChange} 
                        >
                            {featureSlides.map((feature, index) => (
                                <div key={index} className="slide">
                                    <img src={feature.image} alt="panelImage" className="image" />
                                    <h2 className="crouselHeading">{feature.title}</h2>
                                    <span className="text">{feature.text}</span>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                ) : (
                    
                    <div className="finalStepDiv">
                      <h2
                           style={{
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            marginTop:25
                        }}
                          >
                         Free trial will end on&nbsp;
                        {formatDate(
                         new Date(new Date(DATETIME).setDate(new Date(DATETIME).getDate() + 7)).toISOString(),
                         "EEEE, MM/dd"
                         )}
                      </h2>
                      <ul className="list">
                        <li>Drag-and-drop customization</li>
                        <li>Ad-free experience</li>
                        <li>Enhanced privacy</li>
                        <li>More fun games</li>
                      </ul>
                      <p style={{fontSize:"small",marginBottom:30,marginTop:20}}>Send me a reminder email the day before my free trial expires.</p>
                    </div>
                )}

                
                <div className="premiumFeatureModalButton">
                    <BButton
                        type="black"
                        text={reminderSlide ? "DONE" : "NEXT"} 
                        action={handleNext}
                    />
                </div>
            </div>
        </CustomModal>
    );
};

export default PremiumFeatureModal;
