import "./styles.scss";
import React from 'react';
import { useNavigate } from "react-router-dom";
import Preview from "shared/components/preview";

// import Button from "shared/components/bButton";
// import CParagraph from "shared/components/paragraph";
// import { Image, Typography } from "antd";

interface props{
    data: any
}

// const {Paragraph} = Typography

const PreviewBox: React.FC<props> = ({data}) => {
    // const [randomImage, _] = useState(data?.slug?.length % 3)
   
    const navigate = useNavigate()


    return (
        <div className='previewBoxContainer'>
            <div className="topPreview">
                <div className="previewBtn"
                    onClick={() => navigate(`/${data?.slug}?preview=true`)}
                >
                    Preview
                </div>
                <Preview data={data}/>
               
            </div>
          
        </div>
    )
}

export default PreviewBox